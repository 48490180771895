import React from "react";
import "./Now.css";

function Now() {
  return (
    <div className="now">
      <div className="updated">
        <p id="now-p">Updated: Sep 30, 2023</p>
      </div>
      <h4 className="now-title">What I am doing now:</h4>
      <div className="now-section">
        <h4>Work:</h4>
        <p id="now-p">
          The upside to full-stack is that there is so much variety. Plus, an
          application as huge as the one I am working on has so many twists and
          turns that there is always a new corner to explore. Though a react
          devotee, I truly enjoy mastering Angular.js. Java, as always, does the
          job well.
        </p>
      </div>
      <div className="now-section">
        <h4>Family:</h4>
        <p id="now-p">
          The babies are starting to talk, and the wife is loving school. It
          just gets better every day.
        </p>
      </div>
      <div className="now-section">
        <h4>Me:</h4>
        <p id="now-p">
          I sat in on a workshop for Atomic Habits by James Clear at a work
          conference, and though I had read the book, I had not implimented it
          the way that I had planned. This class changed that. I have been
          loving building small habits everyday. I have been pleasure reading
          for weeks, the house is tider, and I am all around happier. I am
          enthusiastic about it!
        </p>
      </div>
    </div>
  );
}

export default Now;
